import React from "react";
import Image from "next/image";
import Local from "../ImageLoader.js";
import Link from "next/link";
import CollapseItem from "./CollapseItem.js";
import { Icon } from "../../icons/icons.js";
import dynamic from "next/dynamic";
import { useTranslation } from "../../context/TranslationContext.js";
import themes from "../../themes/themes.js";
const Fancybox = dynamic(() => import("../Fancybox.js"));

//Sıkça sorulan sorular ve Nasıl Kiralarım? videosunu bağla.

const Faq = ({ data, isHome = 0 }) => {
  const { faqVideoTitle, faqVideoText, showAll } = useTranslation();
  return (
    <div
      className={
        "flex flex-col-reverse sm:flex-col align-top content-start justify-items-start " +
        (isHome ? "relative" : "") +
        (isHome && themes.FooterBanner == false ? " mb-10 " : "")
      }
    >
      <div
        className={
          isHome != 1
            ? "absolute w-full left-0 top-32 sm:top-auto sm:bottom-full h-56 bg-faq-bg lg:bg-none lg:bg-brand-color-primary"
            : "h-52 sm:h-60 bg-faq-bg sm:relative absolute w-full left-0 top-32 sm:top-auto"
        }
      ></div>
      <div
        className={
          "flex relative sm:-mt-14 flex-col sm:flex-col-reverse md:flex-row sm:mb-0  " +
          (isHome == 1 ? "container  mb-5 lg:items-start" : "")
        }
      >
        <Fancybox>
          <div
            className={
              "relative md:mr-6 cursor-pointer md:w-5/12 sm:mt-5 md:mt-0 " +
              (isHome != 1 ? "block sm:hidden px-4" : "")
            }
            data-fancybox="video-gallery"
            data-src="https://www.youtube.com/watch?v=E7eHfwLydYY"
          >
            <div className="text-center h-56 sm:h-full w-full hidden sm:block relative">
              <Image
                src="/images/faq.webp"
                layout="responsive"
                width={519}
                height={432}
                loader={Local}
                alt={"Sıkça Sorulan Sorular"}
              />
            </div>
            <div className=" text-center h-56 sm:h-full w-full block sm:hidden relative">
              <Image
                src="/images/faq.webp"
                layout="fill"
                objectFit="cover"
                loader={Local}
                alt={"Sıkça Sorulan Sorular"}
              />
            </div>
            <div className="absolute top-2/4 left-2/4 flex flex-col items-center w-full lg:w-auto transform -translate-x-2/4 -translate-y-2/4">
              <Icon name={"PlayButton"} />
              <span className="font-bold text-white lg:text-xl mt-4">
                {faqVideoTitle}
              </span>
              <span className="text-orange text-13 font-bold tracking-3.5 mt-1">
                {faqVideoText}
              </span>
            </div>
          </div>
        </Fancybox>

        <div
          className={
            "flex flex-col gap-y-0.5 " +
            (isHome == 1 ? "md:w-2/3" : "w-full px-8 py-4 sm:py-0")
          }
        >
          <div className="flex justify-center sm:justify-between items-center sm:mb-10 sm:-mt-20">
            <Link href={`/${data.page.url}`}>
              <a title={data.page.title}>
                <h3 className="text-white font-bold text-lg sm:text-3xl leading-69">
                  {data.page.baslik}
                  <Icon
                    name={"PaginationNext"}
                    className={"w-2.5 ml-2 inline-block sm:hidden"}
                  ></Icon>
                </h3>
              </a>
            </Link>
            <Link href={`/${data.page.url}`}>
              <a
                className="text-15 text-white font-bold hidden sm:block"
                title={data.page.title}
              >
                {showAll}
              </a>
            </Link>
          </div>
          {data &&
            data.data.map((v, i) => (
              <CollapseItem key={i} title={v.baslik} description={v.icerik} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
