import { Disclosure, Transition } from "@headlessui/react";
import { BiCaretDown } from "@react-icons/all-files/bi/BiCaretDown";

const CollapseItem = ({ title, description }) => {
  return (
    <Disclosure>
      {({ close, open }) => (
        <div>
          <Disclosure.Button className={"w-full text-left"}>
            <div
              className={
                "cursor-pointer flex items-center justify-between px-7 py-4 " +
                (open ? "bg-white" : "bg-brand-gray-7")
              }
            >
              <span className="text-15 text-brand-black flex-1">{title}</span>
              <BiCaretDown
                className={
                  "text-primary transform transition-transform  " +
                  (open ? "rotate-180" : "")
                }
                size="25"
              />
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel>
              <div
                className="opacity-50 text-sm text-brand-black px-12 pb-8 pt-2"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default CollapseItem;
